// pages/PageImageToday.jsx
import React, { useState } from "react";
import { Box, Typography, Pagination, Button } from "@mui/material";
import { useNavigate, useParams } from "react-router";
import Impressions from "../../composant/impressions/impressions";
import { useImpressions } from "../../service/useImpressions";
import ImprimantSucces from "../../composant/message/imprimanteSucces";
import { BackLoadingSimple } from "../../composant/loading/backLoading";

export default function PageImageToday() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const [openPopup, setOpenPopup] = useState(false);

  // Use custom hook to manage images and actions
  const {
    images,
    imprimant,
    selectedImages,
    loading,
    totalPages,
    handleSelectImage,
    handleSelectAll,
    handleDownloadImage,
    send,
    editImage,
  } = useImpressions(currentPage);

  const nomImprimant = imprimant?.nomImprimant;

  // Handle page changes for pagination
  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  return (
    <Box>
      <Typography variant="h4" textAlign="center" gutterBottom>
        Traitement des images
      </Typography>
      {/* Render the reusable Impressions component */}
      <Impressions
        images={images}
        selectedImages={selectedImages}
        handleSelectImage={handleSelectImage}
        handleDownloadImage={handleDownloadImage}
        editImage={editImage}
      />
      <Box display="flex" justifyContent="center" mt={2}>
        <Pagination
          count={totalPages}
          page={currentPage}
          onChange={handlePageChange}
          color="primary"
        />
      </Box>
      <Box
        display="flex"
        justifyContent="flex-end"
        mt={2}
        gap={2}
        p={2}
        position="fixed"
        bottom={0}
        width="100%"
        bgcolor="background.paper"
        boxShadow={3}
      >
        <Button variant="contained" onClick={handleSelectAll}>
          {selectedImages.length === images.length
            ? "Désélectionner tout"
            : "Sélectionner tout"}
        </Button>
        <Button variant="contained" onClick={() => send(nomImprimant, id)}>
          photos today
        </Button>
        <Button
          variant="contained"
          color="error"
          onClick={() => navigate("/admin")}
        >
          Close
        </Button>
      </Box>
      <ImprimantSucces
        open={openPopup}
        onClose={() => {
          setOpenPopup(false);
          navigate("/admin/searchCommand");
        }}
      />
      <BackLoadingSimple open={loading} />
    </Box>
  );
}
