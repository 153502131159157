import {
  Grid,
  Box,
  Typography,
  Badge,
  TextField,
  IconButton,
} from "@mui/material";

import { BackLoadingSimple } from "../../composant/loading/backLoading";
import { useEffect, useState, useCallback } from "react";
import imageCompression from "browser-image-compression";
import { useDispatch, useSelector } from "react-redux";
import { addImageAndSave } from "../../store/slice/gestionImageSlice";
import { useNavigate } from "react-router";
import ImageCard from "../../composant/imageGallery/ImageGallrey";
import PhotoImportCard from "../../composant/UploadFiles";
import { ImportButton } from "../../composant/UploadFiles";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import { useParams } from "react-router";
import { InputAdornment } from "@mui/material";
import DrawerAppBar from "../../composant/navbar/Navbar";
import "simple-keyboard/build/css/index.css";
import CustomKeyboard from "../../composant/clavie/keyboard";
import AddReactionIcon from "@mui/icons-material/AddReaction";
import GraphemeSplitter from "grapheme-splitter";
import CollectionsIcon from "@mui/icons-material/Collections";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { ConfirmClientTextPopup } from "../../composant/Popup/confirmClientText";

export default function ImageUpload() {
  const [loading, setLoading] = useState(false);
  const [images, setImage] = useState([]);
  const { Produits } = useSelector((state) => state.dataApp);
  const { type } = useParams();
  const dataProduit = Produits.find((p) => p.NomProduit === type);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const clientInfo = useSelector((state) => state.InfoCommand.clientCommand);
  // clavie
  const [keyboardOpen, setKeyboardOpen] = useState(false);
  const [activeIndex, setActiveIndex] = useState(null);
  const [inputValue, setInputValue] = useState("");
  const [currentLayout, setCurrentLayout] = useState("default");

  //
  const [popupConfirm, setPopupConfirm] = useState(false);

  const handleKeyPress = (button) => {
    if (button.length === 2 && inputValue.endsWith(button)) {
      return;
    }
    switch (button) {
      case "{shift}":
        setCurrentLayout((prevLayout) =>
          prevLayout === "default" ? "shift" : "default"
        );
        break;
      case "{numbers}":
        setCurrentLayout("numbers");
        break;
      case "{default}":
        setCurrentLayout("default");
        break;
      case "{delete}":
        if (inputValue) {
          const updatedValue = inputValue.slice(0, -2); // Remove the last character
          setInputValue(updatedValue);

          if (activeIndex !== null) {
            handleTextChange(activeIndex, updatedValue);
          }
        }
        break;
      default:
        const newValue = inputValue + button;
        setInputValue(newValue);
        if (activeIndex !== null) {
          handleTextChange(activeIndex, newValue);
        }
        break;
    }
  };

  const handleOpenKeyboard = (index) => {
    console.log("Opening keyboard for index:", index);
    setKeyboardOpen(true);
    setActiveIndex(index);
    setInputValue(images[index]?.text || "");
  };

  const handleCloseKeyboard = () => {
    setKeyboardOpen(false);
  };

  const onChange = (input) => {
    console.log("Keyboard Input:", input);
    if (activeIndex !== null) {
      handleTextChange(activeIndex, input);
    }
  };

  // end clavie

  async function AddImage(e) {
    setLoading(true);
    const acceptedFiles = e.target.files;

    const imageUploadPromises = Array.from(acceptedFiles).map((file) => {
      const options = {
        maxSizeMB: 0.5, // Maximum size in MB
        maxWidthOrHeight: 1920, // Resize the image if it is larger than 1920px
        useWebWorker: true,
      };
      return imageCompression(file, options);
    });

    try {
      const results = await Promise.allSettled(imageUploadPromises);

      // Filter successful compressions
      const compressedFiles = results
        .filter((result) => result.status === "fulfilled")
        .map((result) => result.value);

      // Filter failed compressions for logging or notifications
      const failedFiles = results
        .filter((result) => result.status === "rejected")
        .map((_, index) => acceptedFiles[index]);

      // Add successfully compressed images
      const newImages = compressedFiles.map((compressedFile, index) => ({
        name: acceptedFiles[index].name,
        file: compressedFile,
        idProduit: dataProduit.id,
        count: 1,
      }));

      setImage((prevImages) => [...prevImages, ...newImages]);
      dbUploadImage(type, newImages);
      if (failedFiles.length > 0) {
        alert(
          `Some images failed to upload: ${failedFiles
            .map((file) => file.name)
            .join(", ")}`
        );
      }
    } catch (error) {
      console.error("Unexpected error during image upload:", error);
      alert("An unexpected error occurred. Please try again.");
    } finally {
      setLoading(false);
    }
  }

  function addpanier() {
    const hasText = images.some((img) => img.text);
    if (hasText || clientInfo.telephone) {
      addpanierData(); // Proceed if at least one image has text or clientInfo.telephone exists
    } else {
      setPopupConfirm(true); // Show popup only if no text exists and clientInfo.telephone is missing
    }
  }
  const addpanierData = async () => {
    await dbDeleteAllImages(type);
    dispatch(addImageAndSave({ images: images, type: type }));
    navigate("/commande/inStore");
  };

  const deleteImage = async (index) => {
    setImage(() => images.filter((item, i) => i !== index));
    await dbDeleteImage(type, index);
  };

  const handleTextChange = async (index, Text) => {
    const splitter = new GraphemeSplitter();
    const allowedEmojis = ["😊", "😂", "❤️", "👍", "🔥", "🎉", "🙌"];
    const allowedTextRegex = /^[\p{L}\p{N}\s\p{P}\p{S}]+$/u;
    const emojiRegex = /\p{Extended_Pictographic}/u; // Détecte les emojis Unicode

    const graphemes = splitter.splitGraphemes(Text);

    const newText = graphemes
      .filter(
        (char) =>
          allowedEmojis.includes(char) ||
          (allowedTextRegex.test(char) && !emojiRegex.test(char))
      )
      .join("");

    console.log(newText);

    if (newText.length <= 20) {
      // Update input value and image text
      setInputValue(newText);
      setImage((currentImages) =>
        currentImages.map((img, imgIndex) =>
          imgIndex === index ? { ...img, text: newText } : img
        )
      );

      // Update in IndexedDB
      await dbEditImageText(type, index, newText);
    } else {
      //alert("Text length must not exceed 20 characters, including emojis.");
    }
  };

  const handleCountChange = async (index, delta) => {
    setImage((currentImages) =>
      currentImages.map((img, imgIndex) => {
        if (imgIndex === index) {
          const newCount = Math.max((img.count || 0) + delta, 0); // Ensure count doesn't go below 0
          return { ...img, count: newCount };
        }
        return img;
      })
    );
    const currentImages = [...images];
    const newCount = Math.max((currentImages[index]?.count || 0) + delta, 0);
    await editImageCount(type, index, newCount);
  };

  // Constants for IndexedDB setup
  const dbVersion = 1;
  const dbName = "imageDatabase";
  const storeName = "images";

  function openDB() {
    return new Promise((resolve, reject) => {
      const request = indexedDB.open(dbName, dbVersion);

      request.onerror = (event) => {
        console.error("IndexedDB error:", request.error);
        reject(request.error);
      };

      request.onsuccess = (event) => {
        resolve(request.result);
      };

      request.onupgradeneeded = (event) => {
        const db = event.target.result;
        if (!db.objectStoreNames.contains(storeName)) {
          db.createObjectStore(storeName, { keyPath: "type" });
        }
      };
    });
  }

  async function dbUploadImage(type, images) {
    const db = await openDB();

    return new Promise(async (resolve, reject) => {
      const transaction = db.transaction([storeName], "readwrite");
      const objectStore = transaction.objectStore(storeName);

      // Load existing data within the same transaction
      const request = objectStore.get(type);

      request.onsuccess = async () => {
        const existingData = request.result ? request.result.images : [];
        const updatedImages = [...existingData, ...images];

        // Update the store with the new data
        const putRequest = objectStore.put({ type, images: updatedImages });

        putRequest.onsuccess = () => resolve();
        putRequest.onerror = (event) => reject(putRequest.error);
      };

      request.onerror = (event) => reject(request.error);
    });
  }

  async function dbLoadImages(type) {
    const db = await openDB();
    const transaction = db.transaction([storeName], "readonly");
    const objectStore = transaction.objectStore(storeName);

    const request = objectStore.get(type);

    return new Promise((resolve, reject) => {
      request.onsuccess = () => {
        const result = request.result;
        resolve(result ? result.images : []);
      };
      request.onerror = (event) => reject(request.error);
    });
  }
  async function dbDeleteAllImages(type) {
    const db = await openDB();

    return new Promise((resolve, reject) => {
      const transaction = db.transaction([storeName], "readwrite");
      const objectStore = transaction.objectStore(storeName);

      // Ensure the type (key) is provided
      if (!type) {
        reject(new Error("No key specified for deletion"));
        return;
      }

      // Delete the record with the specified key
      const request = objectStore.delete(type);

      request.onsuccess = () => resolve();
      request.onerror = (event) => reject(request.error);
    });
  }
  async function dbDeleteImage(type, index) {
    const db = await openDB();

    return new Promise((resolve, reject) => {
      const transaction = db.transaction([storeName], "readwrite");
      const objectStore = transaction.objectStore(storeName);

      // Load existing data within the same transaction
      const request = objectStore.get(type);

      request.onsuccess = () => {
        const existingData = request.result ? request.result.images : [];

        if (!existingData || existingData.length <= index) {
          reject(new Error("Image not found for deletion"));
          return;
        }

        // Remove the image at the specified index
        const updatedImages = existingData.filter((_, i) => i !== index);

        // Update the store with the modified data
        const putRequest = objectStore.put({ type, images: updatedImages });

        putRequest.onsuccess = () => resolve();
        putRequest.onerror = (event) => reject(putRequest.error);
      };

      request.onerror = (event) => reject(request.error);
    });
  }
  async function dbEditImageText(type, index, newText) {
    const db = await openDB();

    return new Promise((resolve, reject) => {
      const transaction = db.transaction([storeName], "readwrite");
      const objectStore = transaction.objectStore(storeName);

      // Load existing data within the same transaction
      const request = objectStore.get(type);

      request.onsuccess = () => {
        const existingData = request.result ? request.result.images : [];

        if (!existingData || existingData.length <= index) {
          reject(new Error("Image not found for editing"));
          return;
        }

        // Update the text of the image at the specified index
        existingData[index].text = newText;

        // Update the store with the modified data
        const putRequest = objectStore.put({ type, images: existingData });

        putRequest.onsuccess = () => resolve();
        putRequest.onerror = (event) => reject(putRequest.error);
      };

      request.onerror = (event) => reject(request.error);
    });
  }

  async function editImageCount(type, index, newCount) {
    const db = await openDB();

    return new Promise((resolve, reject) => {
      const transaction = db.transaction([storeName], "readwrite");
      const objectStore = transaction.objectStore(storeName);

      // Load existing data within the same transaction
      const request = objectStore.get(type);

      request.onsuccess = () => {
        const existingData = request.result ? request.result.images : [];

        if (!existingData || existingData.length <= index) {
          reject(new Error("Image not found for editing count"));
          return;
        }

        // Update the count of the image at the specified index
        existingData[index].count = newCount;

        // Update the store with the modified data
        const putRequest = objectStore.put({ type, images: existingData });

        putRequest.onsuccess = () => resolve();
        putRequest.onerror = (event) => reject(putRequest.error);
      };

      request.onerror = (event) => reject(request.error);
    });
  }

  const memoizedDbLoadImages = useCallback(dbLoadImages, []);

  useEffect(() => {
    const loadImageInState = async () => {
      const data = await memoizedDbLoadImages(type);
      setImage(data);
    };

    loadImageInState();
  }, [type, memoizedDbLoadImages]);

  console.log("img data", images);
  return (
    <>
      <DrawerAppBar />
      <div style={{ background: "#f7f7f7", minHeight: "100vh" }}>
        <Box>
          <Grid
            container
            spacing={4}
            sx={{
              marginTop: "8%",
              marginBottom: {
                xs: "15%", // Applies to xs and up, until overridden
                md: "5%", // Overrides the above value from md breakpoint and up
              },
            }}
          >
            <Grid item xs={12}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Typography
                  variant="h6"
                  component="h2"
                  sx={{ fontWeight: "regular" }}
                >
                  Tirage photo
                </Typography>
                <Box
                  component="span"
                  sx={{
                    display: "inline-block",
                    height: "24px",
                    width: "1px",
                  }}
                />
                <Typography
                  variant="h6"
                  component="h2"
                  sx={{ fontWeight: "bold" }}
                >
                  {" "}
                  - Tirage {type}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={4} textAlign="center">
                {images.length === 0 && (
                  <Grid item xs={12} md={6}>
                    <Box
                      component="img"
                      src={dataProduit?.ImageUpload}
                      alt="ImageTirage"
                      width="80%"
                    />
                  </Grid>
                )}
                <Grid
                  item
                  xs={12}
                  md={images.length === 0 ? 6 : 12}
                  textAlign="center"
                >
                  <PhotoImportCard
                    AddImage={AddImage}
                    affichage={images.length === 0}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              sx={{
                width: "100%",
                marginBottom: { xs: "20%", md: "10%" },
              }}
            >
              <Grid container spacing={4} textAlign="center">
                {images?.map((img, index) => (
                  <Grid
                    item
                    xs={12}
                    md={6}
                    lg={3}
                    key={index}
                    sx={{ width: "100%" }}
                    textAlign="center"
                  >
                    <ImageCard
                      src={URL.createObjectURL(img.file)}
                      onDelete={() => deleteImage(index)}
                      type={type}
                      badgeContent={img.count}
                    />
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                      width="100%"
                      mb={1}
                    >
                      <IconButton
                        onClick={() => {
                          handleCountChange(index, -1);
                        }}
                        style={{ color: "#1976d2" }}
                      >
                        <RemoveIcon />
                      </IconButton>
                      <TextField
                        key={index}
                        variant="outlined"
                        sx={{
                          //  width: "80%",
                          background: "white",
                          marginTop: "10px",
                          "& .MuiOutlinedInput-root": {
                            "& fieldset": {
                              borderColor: "grey",
                              borderRadius: "15px",
                            },
                            "&:hover fieldset": {
                              borderColor: "black", // Border color when input is not focused
                            },
                            "&.Mui-focused fieldset": {
                              borderColor: "black", // Border color when input is focused
                            },
                          },
                        }}
                        value={img.text || ""}
                        onChange={(e) =>
                          handleTextChange(index, e.target.value)
                        }
                        placeholder="Votre Texte"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                onClick={() => handleOpenKeyboard(index)}
                              >
                                <AddReactionIcon />
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                      <IconButton
                        onClick={() => {
                          handleCountChange(index, +1);
                        }}
                        style={{ color: "#1976d2" }}
                      >
                        <AddIcon />
                      </IconButton>
                    </Box>

                    {keyboardOpen && activeIndex === index && (
                      <CustomKeyboard
                        inputValue={inputValue}
                        onChange={onChange}
                        onKeyPress={handleKeyPress}
                        layoutName={currentLayout}
                        closeKeyboard={handleCloseKeyboard}
                        showEmojiOnly={true}
                      />
                    )}
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>

          {images.length !== 0 && (
            <>
              <Box sx={{ display: { xs: "none", md: "flex" } }}>
                <footer
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    borderTop: "1px solid #ccc",
                    position: "fixed",
                    left: "0",
                    bottom: "0",
                    width: "100%",
                    background: "white",
                    paddingLeft: "2%",
                    paddingRight: "2%",
                  }}
                >
                  <ImportButton
                    variant="contained"
                    height="70px"
                    startIcon={
                      <Badge
                        badgeContent={images.length}
                        sx={{
                          "& .MuiBadge-badge": {
                            color: "#e63e57",
                            backgroundColor: "white",
                          },
                        }}
                      >
                        <AddShoppingCartIcon />
                      </Badge>
                    }
                    onClick={addpanier}
                  >
                    Ajouter au Panier
                  </ImportButton>
                </footer>
              </Box>
              <Box sx={{ display: { xs: "flex", md: "none" } }}>
                <footer
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    position: "fixed",
                    left: "0",
                    bottom: "0",
                    width: "100%",
                    background: "white",
                    paddingLeft: "2%",
                    paddingRight: "2%",
                  }}
                >
                  <ImportButton
                    variant="contained"
                    fullWidth
                    height="70px"
                    startIcon={
                      <Badge
                        badgeContent={images.length}
                        fontSize="large"
                        sx={{
                          "& .MuiBadge-badge": {
                            color: "#e63e57",
                            backgroundColor: "white",
                          },
                        }}
                      >
                        <AddShoppingCartIcon />
                      </Badge>
                    }
                    onClick={addpanier}
                  >
                    imprimer
                  </ImportButton>
                </footer>
              </Box>
            </>
          )}
          <ConfirmClientTextPopup
            open={popupConfirm}
            onClose={() => {
              setPopupConfirm(false);
            }}
            confirm={addpanierData}
          />
          <BackLoadingSimple open={loading} />
        </Box>
      </div>
    </>
  );
}
