import * as React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Indexdasbord from "../../composant/navbar/indexDashbord";
import { Box, Button, Grid, IconButton, TextField } from "@mui/material";

import { useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import Datepicker from "react-tailwindcss-datepicker";
import { BackLoadingSimple } from "../../composant/loading/backLoading";

import { GetCommand } from "../../store/slice/CommandDahbordSlice";
import { TableCommands } from "../../composant/Tables/TableCommands";
import { useState } from "react";

export default function PageCommandDashbord() {
  const { loading } = useSelector((state) => state.commands);
  return (
    <>
      <Indexdasbord
        Children={
          <Box sx={{ width: "90%", overflow: "hidden", marginLeft: "5%" }}>
            <CommandDashbord />
          </Box>
        }
      />
      <BackLoadingSimple open={loading} />
    </>
  );
}

export function CommandDashbord({ filterDisplay = true }) {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const { commands, alertMessage, etat } = useSelector(
    (state) => state.commands
  );
  const { user } = useSelector((state) => state.Auth);
  const [nomFilter, setnomFilter] = React.useState("");
  const [telephoneFilter, setTelephoneFilter] = React.useState("");
  const [codeFilter, setCodeFilter] = useState("");
  const dispatch = useDispatch();
  const [DateRange, setDateRange] = useState({
    startDate: null,
    endDate: null,
  });

  const formatDateForAPI = (date) => {
    return date ? new Date(date).toISOString().split("T")[0] : "";
  };

  function reset() {
    setDateRange({ startDate: null, endDate: null });
    setCodeFilter("");
    setTelephoneFilter("");
    setnomFilter("");
  }

  const getCommadParData = useCallback(() => {
    const data = {
      page,
      rowsPerPage,
      nomFilter: nomFilter.trim() || "",
      telephoneFilter: telephoneFilter.trim() || "",
      codeFilter: codeFilter.trim() || "",
      DateRange: {
        startDate: formatDateForAPI(DateRange.startDate) || null,
        endDate: formatDateForAPI(DateRange.endDate) || null,
      },
    };

    dispatch(GetCommand(data));
  }, [
    dispatch,
    page,
    rowsPerPage,
    nomFilter,
    telephoneFilter,
    DateRange,
    codeFilter,
  ]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      getCommadParData();
    }, 1000);

    return () => clearTimeout(timeout);
  }, [page, rowsPerPage, getCommadParData]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <>
      <Paper sx={{ padding: "2%", minHeight: "600px" }}>
        <Grid container spacing={2}>
          <Grid item xs={2} padding="2%">
            <TextField
              placeholder="Nom"
              type="text"
              variant="outlined"
              sx={{ background: "#F8F8F8" }}
              value={nomFilter}
              fullWidth
              onChange={(e) => setnomFilter(e.target.value)}
            />
          </Grid>

          <Grid item xs={2} padding="2%">
            <TextField
              placeholder="telephone"
              type="tel"
              variant="outlined"
              sx={{ background: "#F8F8F8" }}
              value={telephoneFilter}
              onChange={(e) => setTelephoneFilter(e.target.value)}
              fullWidth
            />
          </Grid>
          <Grid item xs={2} padding="2%">
            <TextField
              placeholder="code"
              type="number"
              variant="outlined"
              sx={{ background: "#F8F8F8" }}
              value={codeFilter}
              onChange={(e) => setCodeFilter(e.target.value)}
              fullWidth
            />
          </Grid>
          <Grid item xs={4} padding="2%">
            <Datepicker
              inputClassName="w-full h-14 rounded-md focus:ring-0 font-normal bg-[#F8F8F8] border border-gray-300 placeholder:text-black-100 text-black dark:bg-black-900 dark:placeholder:text-black-100"
              primaryColor={"blue"}
              value={DateRange}
              onChange={(newDateRange) => {
                setDateRange(newDateRange);
                setPage(0);
              }}
              showShortcuts={true}
              showFooter={true}
              popoverDirection="down"
            />
          </Grid>
          <Grid item xs={2} padding="2%">
            <Button variant="contained" onClick={reset}>
              Réinitialiser
            </Button>
          </Grid>

          <Grid item xs={12}>
            <TableCommands
              commands={commands}
              refreshCommands={getCommadParData}
              rowsPerPage={rowsPerPage}
              page={page}
              handleChangePage={handleChangePage}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </Grid>
        </Grid>
      </Paper>
    </>
  );
}
