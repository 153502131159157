import React, { useEffect } from "react";
import { Box, Typography, Button } from "@mui/material";
import { useNavigate, useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { BackLoadingSimple } from "../../composant/loading/backLoading";
import ImprimantSucces from "../../composant/message/imprimanteSucces";
import Impressions from "../../composant/impressions/impressions";
import { useImpressions } from "../../service/useImpressions";
import EditImagePopup from "../../composant/EditImage";
import { GetImageParCommand } from "../../store/slice/CommandDahbordSlice";

const downloadImage = (url, imageId) => {
  const link = document.createElement("a");
  link.href = url;
  link.download = `image_${imageId}`;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export default function PageCommandTraiteParcode() {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // Retrieve images and imprimant data from the Redux store
  const images = useSelector((state) => state.commands?.PopupData?.images);
  const imprimant = useSelector(
    (state) => state.commands?.PopupData?.imprimant
  );
  const nomImprimant = useSelector(
    (state) => state.commands?.PopupData?.imprimant?.nomImprimant
  );

  useEffect(() => {
    dispatch(GetImageParCommand(id));
  }, [dispatch, id]);

  // Use our custom hook.
  // The hook returns all state & functions used by the Impressions component
  const {
    allImages,
    selectedImages,
    openCropper,
    demonsion,
    src,
    loading,
    openPopup,
    handleSelectImage,
    handleSelectAll,
    editCountImage,
    sendToPrint,
    editImage, // Function to edit the image after cropping
    openImageCropper, // Opens the crop dialog for a specific image
    setOpenCropper,
    setOpenPopup,
  } = useImpressions(images, id, imprimant);

  return (
    <Box>
      <Typography variant="h4" textAlign="center" gutterBottom>
        Traitement des images
      </Typography>
      <Box sx={{ marginBottom: "200px" }}>
        <Impressions
          allImages={allImages}
          selectedImages={selectedImages}
          handleSelectImage={handleSelectImage}
          downloadImage={downloadImage}
          openImageCropper={openImageCropper}
          editCountImage={editCountImage}
        />
      </Box>
      <Box
        position="fixed"
        bottom={0}
        width="100%"
        bgcolor="background.paper"
        p={2}
        boxShadow={3}
        display="flex"
        justifyContent="right"
        gap={2}
      >
        <Button variant="contained" onClick={handleSelectAll}>
          {selectedImages?.length === allImages?.length
            ? "Désélectionner tout"
            : "Sélectionner tout"}
        </Button>
        <Button variant="contained" onClick={sendToPrint}>
          Imprimer Images
        </Button>
        <Button
          variant="contained"
          color="error"
          onClick={() => navigate(`/admin/CommandClientPage/${id}`)}
        >
          Terminer
        </Button>
      </Box>
      <EditImagePopup
        open={openCropper}
        onClose={() => setOpenCropper(false)}
        src={src}
        demonsion={demonsion}
        onSubmit={editImage}
      />
      <ImprimantSucces open={openPopup} onClose={() => setOpenPopup(false)} />
      <BackLoadingSimple open={loading} />
    </Box>
  );
}
