import { Dialog, Button } from "@mui/material";
import React, { useState } from "react";
import { Wheel } from "react-custom-roulette";

const data = [
  { option: "0", style: { backgroundColor: "#59cbd7", textColor: "white" } },
  { option: 10, style: { backgroundColor: "white", textColor: "black" } },
  { option: 20, style: { backgroundColor: "#59cbd7", textColor: "white" } },
  { option: 30, style: { backgroundColor: "white", textColor: "black" } },
  { option: 40, style: { backgroundColor: "#59cbd7", textColor: "white" } },
  { option: 50, style: { backgroundColor: "white", textColor: "black" } },
  { option: "0", style: { backgroundColor: "#59cbd7", textColor: "white" } },
  { option: 10, style: { backgroundColor: "white", textColor: "black" } },
  { option: 20, style: { backgroundColor: "#59cbd7", textColor: "white" } },
  { option: 30, style: { backgroundColor: "white", textColor: "black" } },
  { option: 40, style: { backgroundColor: "#59cbd7", textColor: "white" } },
  { option: 50, style: { backgroundColor: "white", textColor: "black" } },
];
const stylesDialog = {
  dialog: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  button: {
    backgroundColor: "#FF1744",
    color: "white",
    textTransform: "none",
    width: "100%",
    padding: "10px 0",
    marginTop: "20px",
  },
};

export default function PopupRoulette({ open, onClose, remise }) {
  const [mustSpin, setMustSpin] = useState(false);
  const [prizeNumber, setPrizeNumber] = useState(null);
  const [result, setResult] = useState("");

  const handleSpinClick = () => {
    //  const newPrizeNumber = Math.floor(Math.random() * data.length);
    const number = data.findIndex((dt) => parseInt(dt.option, 0) === remise);
    setPrizeNumber(number);
    setMustSpin(true);
  };

  return (
    <Dialog
      open={open}
      sx={stylesDialog.dialog}
      PaperProps={{ style: { borderRadius: 20 } }}
    >
      <div style={styles.container}>
        <Wheel
          mustStartSpinning={mustSpin}
          prizeNumber={prizeNumber}
          data={data}
          innerBorderWidth={5}
          outerBorderWidth={8}
          radiusLineWidth={3}
          outerBorderColor="#444"
          innerBorderColor="#ccc"
          radiusLineColor="#ddd"
          backgroundColors={["#3e3e3e", "#df3428"]}
          textColors={["#ffffff"]}
          onStopSpinning={() => {
            setMustSpin(false);
            setResult(`Ta remise est de : ${data[prizeNumber]?.option}`);
          }}
        />

        {result ? (
          <>
            <h3 style={styles.result}>{result}</h3>
            <Button variant="contained" onClick={onClose} style={styles.button}>
              Continuer
            </Button>
          </>
        ) : (
          <button style={styles.button} onClick={handleSpinClick}>
            🎡 Spin the Wheel
          </button>
        )}
      </div>
    </Dialog>
  );
}

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100vh",
    backgroundColor: "#f5f5f5",
    fontFamily: "Arial, sans-serif",
  },
  button: {
    marginTop: 20,
    padding: "12px 24px",
    fontSize: "18px",
    fontWeight: "bold",
    color: "#fff",
    backgroundColor: "#e63e57",
    border: "none",
    borderRadius: "8px",
    cursor: "pointer",
    transition: "background 0.3s",
  },
  buttonHover: {
    backgroundColor: "#d43f00",
  },
  result: {
    marginTop: 20,
    fontSize: "24px",
    fontWeight: "bold",
    color: "#333",
  },
};
