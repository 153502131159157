import React, { useState } from "react";
import { Wheel } from "react-custom-roulette";

const data = [
  { option: "0", style: { backgroundColor: "#e63e57", textColor: "white" } },
  { option: 10, style: { backgroundColor: "white", textColor: "black" } },
  { option: 20, style: { backgroundColor: "#e63e57", textColor: "white" } },
  { option: 30, style: { backgroundColor: "white", textColor: "black" } },
  { option: 40, style: { backgroundColor: "#e63e57", textColor: "white" } },
  { option: 50, style: { backgroundColor: "white", textColor: "black" } },
  { option: "0", style: { backgroundColor: "#e63e57", textColor: "white" } },
  { option: 10, style: { backgroundColor: "white", textColor: "black" } },
  { option: 20, style: { backgroundColor: "#e63e57", textColor: "white" } },
  { option: 30, style: { backgroundColor: "white", textColor: "black" } },
  { option: 40, style: { backgroundColor: "#e63e57", textColor: "white" } },
  { option: 50, style: { backgroundColor: "white", textColor: "black" } },
];

export default function RouletteWheel() {
  const [mustSpin, setMustSpin] = useState(false);
  const [prizeNumber, setPrizeNumber] = useState(null);
  const [result, setResult] = useState("");

  const handleSpinClick = () => {
    //  const newPrizeNumber = Math.floor(Math.random() * data.length);
    const number = data.findIndex((dt) => dt.option === 10);
    setPrizeNumber(number);
    setMustSpin(true);
  };

  return (
    <div style={styles.container}>
      <Wheel
        mustStartSpinning={mustSpin}
        prizeNumber={prizeNumber}
        data={data}
        innerBorderWidth={5}
        outerBorderWidth={8}
        radiusLineWidth={3}
        outerBorderColor="#444"
        innerBorderColor="#ccc"
        radiusLineColor="#ddd"
        backgroundColors={["#3e3e3e", "#df3428"]}
        textColors={["#ffffff"]}
        onStopSpinning={() => {
          setMustSpin(false);
          setResult(`You got: ${data[prizeNumber]?.option}`);
        }}
      />
      <button style={styles.button} onClick={handleSpinClick}>
        🎡 Spin the Wheel
      </button>
      {result && <h3 style={styles.result}>{result}</h3>}
    </div>
  );
}

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100vh",
    backgroundColor: "#f5f5f5",
    fontFamily: "Arial, sans-serif",
  },
  button: {
    marginTop: 20,
    padding: "12px 24px",
    fontSize: "18px",
    fontWeight: "bold",
    color: "#fff",
    backgroundColor: "#e63e57",
    border: "none",
    borderRadius: "8px",
    cursor: "pointer",
    transition: "background 0.3s",
  },
  buttonHover: {
    backgroundColor: "#d43f00",
  },
  result: {
    marginTop: 20,
    fontSize: "24px",
    fontWeight: "bold",
    color: "#333",
  },
};
