import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Box,
  Button,
  Typography,
} from "@mui/material";

export function ConfirmDeletePopup({ open, onClose, confirm }) {
  return (
    <Dialog open={open} fullWidth maxWidth="xs">
      <DialogTitle
        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        Confirmer Suppression
      </DialogTitle>

      <DialogContent>
        <Typography>Confirmez-vous la suppression ?</Typography>
      </DialogContent>
      <DialogActions>
        <Box sx={{ display: "flex", flexDirection: "row" }}>
          <Button variant="contained" color="error" onClick={onClose}>
            Annulé
          </Button>
          <Button
            variant="contained"
            onClick={confirm}
            sx={{ marginLeft: "2%" }}
          >
            Confirmez
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
}
