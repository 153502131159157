import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import QRCode from "react-qr-code";
import { urlQrCode } from "../../env";

const QRCodePopup = ({ open, onClose, id }) => {
  const [qrValue, setQrValue] = useState(`${urlQrCode}?magasin=${id}`);

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle sx={{ textAlign: "center" }}>QR Code</DialogTitle>
      <DialogContent style={{ textAlign: "center", padding: "20px" }}>
        <QRCode value={qrValue} size={200} />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="error" variant="contained">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default QRCodePopup;
