import React from "react";
import { Snackbar, Alert as MuiAlert } from "@mui/material";
import { useMediaQuery, useTheme } from "@mui/material";

export default function CustomAlert({ open, onClose, etat, message }) {
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down("md"));

  const anchorOrigin = isXs
    ? { vertical: "bottom", horizontal: "right" }
    : { vertical: "top", horizontal: "right" };
  return (
    <Snackbar
      open={open}
      autoHideDuration={3000}
      onClose={onClose}
      anchorOrigin={anchorOrigin}
    >
      <MuiAlert
        onClose={onClose}
        severity={etat}
        variant="filled"
        sx={{ width: "100%" }}
      >
        {message}
      </MuiAlert>
    </Snackbar>
  );
}
