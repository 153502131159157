// pages/GalleryImageTraite.jsx
import React from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Button,
  Typography,
  Box,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { BackLoadingSimple } from "../loading/backLoading";
import ImprimantSucces from "../../composant/message/imprimanteSucces";

import Impressions from "../../composant/impressions/impressions";
import { useImpressions } from "../../service/useImpressions";
import ImageCropDialog from "../EditImage";

export default function GalleryImageTraite({
  open,
  onClose,
  downloadImage,
  id,
}) {
  const images = useSelector((state) => state.commands?.PopupData?.images);
  const imprimant = useSelector(
    (state) => state.commands?.PopupData?.imprimant
  );

  // Use our custom hook – passing the images, command id, and imprimant
  const {
    allImages,
    selectedImages,
    openCropper,
    demonsion,
    src,
    loading,
    openPopup,
    handleSelectImage,
    handleSelectAll,
    editCountImage,
    sendToPrint,
    editImage,
    openImageCropper,
    setOpenCropper,
    setOpenPopup,
  } = useImpressions(images, id, imprimant);

  return (
    <>
      <Dialog open={open} fullScreen>
        <DialogTitle>
          <Typography variant="h4" textAlign="center" gutterBottom>
            Images Traitées
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Impressions
            allImages={allImages}
            selectedImages={selectedImages}
            handleSelectImage={handleSelectImage}
            downloadImage={downloadImage}
            openImageCropper={openImageCropper}
            editCountImage={editCountImage}
          />
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={handleSelectAll}>
            {selectedImages?.length === allImages?.length
              ? "Désélectionner Tout"
              : "Tout Sélectionner"}
          </Button>
          <Button variant="contained" onClick={sendToPrint}>
            Imprimer Images
          </Button>
          <Button onClick={onClose} variant="contained" color="error">
            Annulé
          </Button>
        </DialogActions>
      </Dialog>
      {/* Crop Dialog */}
      <ImageCropDialog
        open={openCropper}
        onClose={() => setOpenCropper(false)}
        src={src}
        demonsion={demonsion}
        onSubmit={editImage}
      />
      <ImprimantSucces open={openPopup} onClose={() => setOpenPopup(false)} />
      <BackLoadingSimple open={loading} />
    </>
  );
}
