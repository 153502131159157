import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { useCallback, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GetStatus } from "../../store/slice/DataApp";
import { Api_Token } from "../../env";
import { EditStatus } from "../../store/slice/CommandDahbordSlice";

function EditStatusPopup({ open, onClose, id }) {
  const [statusId, setStatusId] = useState();
  const { status } = useSelector((state) => state.commands);
  const dispatch = useDispatch();

  function Send() {
    const data = {
      id: id,
      idStatus: statusId,
    };

    dispatch(EditStatus(data))
      .then((result) => {
        if (result?.payload) {
          //  console.log("Status updated successfully", result.payload);
          // Optionally, show a success message or update UI
          setStatusId(null);
          onClose();
        } else {
          console.error("Failed to update status", result);
        }
      })
      .catch((error) => {
        console.error("Error updating status:", error);
      });
  }
  return (
    <Dialog open={open} fullWidth maxWidth="xs">
      <DialogTitle sx={{ textAlign: "center" }}>
        <h3>Edit status</h3>
      </DialogTitle>
      <DialogContent>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Status</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={statusId}
            label="Age"
            onChange={(e) => {
              setStatusId(e.target.value);
            }}
          >
            {status?.map((s) => (
              <MenuItem value={s.id}>{s.NameStatus}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Box sx={{ display: "flex", flexDirection: "row" }}>
          <Button variant="contained" color="error" onClick={onClose}>
            onClose
          </Button>
          <Button variant="contained" onClick={Send} sx={{ marginLeft: "2%" }}>
            confirm
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
}

export default EditStatusPopup;
