import React, { useEffect, useState, useCallback } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Paper,
  Box,
  Typography,
} from "@mui/material";
import { useNavigate, useParams } from "react-router";
import { Api_Token } from "../../env";
import { TableCommands } from "../../composant/Tables/TableCommands";

export default function CommandClientPage() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [commands, setCommand] = useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const GetDataCommand = useCallback(() => {
    Api_Token.get(`command/getCommandsByPhone/${id}`, {
      params: { page, limit: rowsPerPage + 1 },
    })
      .then((response) => {
        setCommand(response.data);
      })
      .catch((error) => {
        console.error("Error fetching commands:", error);
      });
  }, [id, page, rowsPerPage]);
  useEffect(() => {
    GetDataCommand();
  }, [GetDataCommand]);
  return (
    <>
      <Typography variant="h5" sx={{ textAlign: "center" }}>
        Liste des Commandes Clients
      </Typography>
      <Paper sx={{ width: "90%", margin: "auto", overflow: "hidden" }}>
        <TableCommands
          commands={commands}
          refreshCommands={GetDataCommand}
          rowsPerPage={rowsPerPage}
          page={page}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
      <Box
        position="fixed"
        bottom={0}
        width="100%"
        bgcolor="background.paper"
        p={2}
        boxShadow={3}
        display="flex"
        justifyContent="Right"
        gap={2}
      >
        <Button
          variant="contained"
          color="error"
          onClick={() => {
            navigate(`/admin/searchCommand`);
          }}
        >
          finish
        </Button>
      </Box>
    </>
  );
}
