import * as React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Indexdasbord from "../../composant/navbar/indexDashbord";
import { Box, Button, IconButton, TextField } from "@mui/material";
import CollectionsIcon from "@mui/icons-material/Collections";
import { Badge } from "@mui/material";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import SettingsIcon from "@mui/icons-material/Settings";
import { useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Snackbar, Alert } from "@mui/material";
import PriceCheckIcon from "@mui/icons-material/PriceCheck";
import { BackLoadingSimple } from "../../composant/loading/backLoading";
import { Api_Token } from "../../env";
import GalleryImageTraite from "../../composant/admin/galleryImageTraite";
import axios from "axios";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import EditIcon from "@mui/icons-material/Edit";
import EditStatusPopup from "../../composant/Popup/EditStatus";
import {
  GetStatus,
  TraiteCommand,
  GetImageParCommand,
  PayeCommand,
  AnnuleCommand,
  DeleteCommand,
} from "../../store/slice/CommandDahbordSlice";
import { useAlertContext } from "../../service/AlertServices";
import ClearIcon from "@mui/icons-material/Clear";
import { ConfirmPaymentPopup } from "../Popup/confirmPayment";
import { ConfirmAnnulePopup } from "../Popup/confirmAnnule";
import DeleteIcon from "@mui/icons-material/Delete";
import { ConfirmDeletePopup } from "../Popup/confirmDelete";
const columns = [
  { id: 0, align: "center", minWidth: "60px", label: "Nom" },
  { id: 1, align: "center", minWidth: "60px", label: "Telephone" },
  { id: 2, align: "center", minWidth: "60px", label: "status" },
  { id: 2, align: "center", minWidth: "60px", label: "prix" },
  { id: 8, align: "center", minWidth: "60px", label: "réduction" },
  { id: 10, align: "center", minWidth: "60px", label: "payment status" },
  { id: 3, align: "center", minWidth: "60px", label: "code" },
  { id: 4, align: "center", minWidth: "60px", label: "Date" },
  { id: 5, align: "center", minWidth: "60px", label: "Original" },
  { id: 6, align: "center", minWidth: "60px", label: "Traite" },
  { id: 9, align: "center", minWidth: "60px", label: "Action" },
];

export function TableCommands({
  commands,
  refreshCommands,
  rowsPerPage,
  page,
  handleChangePage,
  handleChangeRowsPerPage,
}) {
  const [PopupTraite, setPopupTraite] = React.useState(false);
  const [IdPopupTraite, setIdPopupTraite] = React.useState();
  const [PopupEdit, setPopupEdit] = React.useState(false);
  const [idPopupEdit, setidPopupEdit] = React.useState();
  const { alertMessage, etat } = useSelector((state) => state.commands);
  const { user } = useSelector((state) => state.Auth);
  const { showAlert } = useAlertContext();
  const [idPayment, setIdPayment] = React.useState();
  const [PopupPayment, setPopupPayment] = React.useState(false);
  const [idAnnulation, setIdAnnulation] = React.useState();
  const [PopupAnnulation, setPopupAnnulation] = React.useState(false);
  const [idDelete, setIdDelete] = React.useState();
  const [PopupDelete, setPopupDelete] = React.useState(false);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(GetStatus());
  }, []);

  function getDate(dt) {
    const date = new Date(dt);
    const year = date.getFullYear();
    const month = date.getMonth() + 1; // Note: Months are zero-indexed
    const day = date.getDate();
    const hours = date.getHours();
    const minutes = date.getMinutes();

    return `${year}-${month}-${day} | ${hours}:${minutes}`;
  }
  function traite(id) {
    const data = {
      idCommand: id,
    };
    dispatch(TraiteCommand(data)).then((result) => {
      if (TraiteCommand.fulfilled.match(result)) {
        refreshCommands();
        showAlert("Commande traitée avec succès", "success");
      }
      if (TraiteCommand.rejected.match(result)) {
        showAlert("Erreur lors du traitement de la commande", "error");
      }
    });

    //  showAlert(alertMessage, etat);
  }

  function paye() {
    const data = {
      id: idPayment,
    };
    dispatch(PayeCommand(data)).then((result) => {
      if (PayeCommand.fulfilled.match(result)) {
        refreshCommands();
        setPopupPayment(false);
        showAlert("La commande a été payée avec succès.", "success");
      }
      if (PayeCommand.rejected.match(result)) {
        showAlert("Erreur lors du paiement de la commande", "error");
      }
    });

    //  showAlert(alertMessage, etat);
  }

  function Annule() {
    const data = {
      id: idAnnulation,
    };
    dispatch(AnnuleCommand(data)).then((result) => {
      if (AnnuleCommand.fulfilled.match(result)) {
        refreshCommands();
        setIdAnnulation();
        setPopupAnnulation(false);
        showAlert("La commande a été annulée avec succès.", "success");
      }
      if (AnnuleCommand.rejected.match(result)) {
        showAlert("Erreur lors du annulée de la commande", "error");
      }
    });
  }

  function Delete() {
    const data = {
      id: idDelete,
    };
    dispatch(DeleteCommand(data)).then((result) => {
      if (DeleteCommand.fulfilled.match(result)) {
        refreshCommands();
        setIdDelete();
        setPopupDelete(false);
        showAlert("La commande a été supprimé avec succès.", "success");
      }
      if (DeleteCommand.rejected.match(result)) {
        showAlert("Erreur lors du supprimé  de la commande", "error");
      }
    });
  }

  async function downloadtraiteZib(id, nom) {
    try {
      const res = await Api_Token.get(`/command/traiteZibCommand/${id}`, {
        responseType: "blob",
        headers: {
          "Content-Type": "application/json",
        },
      });
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${nom}_traite.zip`);
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      console.error("Error downloading image:", error);
    }
  }

  async function downloadOriginalZib(id, nom) {
    try {
      const res = await Api_Token.get(`/command/zibCommand/${id}`, {
        responseType: "blob",
        headers: {
          "Content-Type": "application/json",
        },
      });
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${nom}_original.zip`);
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      console.error("Error downloading image:", error);
    }
  }

  async function downloadImage(url, id) {
    try {
      const response = await axios.get(url, { responseType: "blob" });
      const link = document.createElement("a");
      const objectURL = URL.createObjectURL(response.data);
      link.href = objectURL;
      link.download = `image${id}`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(objectURL);
    } catch (error) {
      console.error("Error downloading image:", error);
    }
  }

  function getImagesPopup(id) {
    dispatch(GetImageParCommand(id)).then((result) => {
      if (GetImageParCommand.fulfilled.match(result)) {
        setPopupTraite(true);
      }
    });
  }

  return (
    <>
      <TableContainer sx={{ maxHeight: 440 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{
                    minWidth: column.minWidth,
                  }}
                >
                  {column.label}
                </TableCell>
              ))}
              <TableCell style={{ top: 54 }}></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {commands?.data?.map((row) => {
              return (
                <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                  <TableCell align="center">{row.NomClient}</TableCell>
                  <TableCell align="center">{row.Telephone}</TableCell>
                  <TableCell align="center">{row.NameStatus}</TableCell>
                  <TableCell align="center">{row.prix} dhs</TableCell>
                  <TableCell align="center">{row.reduction} %</TableCell>
                  <TableCell align="center">
                    {row.paye === 0 ? "Non payé" : "payé"}
                  </TableCell>
                  <TableCell align="center">{row.code}</TableCell>
                  <TableCell align="center">{getDate(row.createdAt)}</TableCell>
                  <TableCell align="center">
                    <Badge badgeContent={row.ImageCommdeCount} color="error">
                      <CollectionsIcon />
                    </Badge>
                    <IconButton
                      onClick={() => {
                        downloadOriginalZib(row.id, row.NomClient);
                      }}
                    >
                      <ArrowDownwardIcon />
                    </IconButton>
                  </TableCell>

                  {row.etat === "en attente" ? (
                    <TableCell align="center">
                      <IconButton
                        onClick={() => {
                          traite(row.id);
                        }}
                      >
                        <SettingsIcon />
                      </IconButton>
                    </TableCell>
                  ) : (
                    <TableCell align="center">
                      <IconButton
                        onClick={() => {
                          getImagesPopup(row.id);
                          setIdPopupTraite(row.id);
                        }}
                      >
                        <Badge
                          badgeContent={row.ImageCommdeCount}
                          color="error"
                        >
                          <CollectionsIcon />
                        </Badge>
                      </IconButton>
                      <IconButton
                        onClick={() => {
                          downloadtraiteZib(row.id, row.NomClient);
                        }}
                      >
                        <ArrowDownwardIcon />
                      </IconButton>
                    </TableCell>
                  )}

                  <TableCell align="center">
                    {row.etat !== "en attente" && (
                      <>
                        <IconButton
                          onClick={() => {
                            traite(row.id);
                          }}
                        >
                          <AutorenewIcon />
                        </IconButton>

                        <IconButton
                          onClick={() => {
                            //  setPopupEdit(true);
                            //  setidPopupEdit(row.id);
                            setPopupPayment(true);
                            setIdPayment(row.id);
                          }}
                        >
                          <PriceCheckIcon color="success" />
                        </IconButton>

                        <IconButton
                          onClick={() => {
                            setPopupAnnulation(true);
                            setIdAnnulation(row.id);
                            //  setPopupEdit(true);
                            //  setidPopupEdit(row.id);
                          }}
                        >
                          <ClearIcon color="error" />
                        </IconButton>
                        {user.Role === "gerant" && (
                          <IconButton
                            onClick={() => {
                              setPopupDelete(true);
                              setIdDelete(row.id);
                              //  setPopupEdit(true);
                              //  setidPopupEdit(row.id);
                            }}
                          >
                            <DeleteIcon color="error" />
                          </IconButton>
                        )}
                      </>
                    )}

                    {/**   <IconButton
                      onClick={() => {
                        setPopupEdit(true);
                        setidPopupEdit(row.id);
                      }}
                    >
                      <EditIcon />
                    </IconButton>*/}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 15]}
        component="div"
        count={commands?.totalCount || 0}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      <GalleryImageTraite
        open={PopupTraite}
        onClose={() => {
          setPopupTraite(false);
        }}
        downloadImage={downloadImage}
        id={IdPopupTraite}
      />
      <EditStatusPopup
        open={PopupEdit}
        onClose={() => setPopupEdit(false)}
        id={idPopupEdit}
      />
      <ConfirmPaymentPopup
        open={PopupPayment}
        onClose={() => setPopupPayment(false)}
        confirm={paye}
      />
      <ConfirmAnnulePopup
        open={PopupAnnulation}
        onClose={() => {
          setPopupAnnulation(false);
        }}
        confirm={Annule}
      />
      <ConfirmDeletePopup
        open={PopupDelete}
        onClose={() => setPopupDelete(false)}
        confirm={Delete}
      />
    </>
  );
}
