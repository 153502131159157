import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { Link } from "react-router-dom";
import HomeIcon from "@mui/icons-material/Home";
import PermMediaIcon from "@mui/icons-material/PermMedia";
import SettingsIcon from "@mui/icons-material/Settings";
import PrintIcon from "@mui/icons-material/Print";
import SearchIcon from "@mui/icons-material/Search";
import LogoutIcon from "@mui/icons-material/Logout";
import Cookies from "js-cookie";
import { useSelector } from "react-redux";

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  backgroundColor: "white",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": { ...openedMixin(theme), backgroundColor: "white" },
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": { ...closedMixin(theme), backgroundColor: "white" },
  }),
}));

const menuItems = {
  gerant: [
    { text: "Home", icon: <HomeIcon />, path: "/admin" },
    { text: "Commande", icon: <PermMediaIcon />, path: "/admin/command" },
    { text: "Paramètre", icon: <SettingsIcon />, path: "/admin/parametre" },
    { text: "Imprimante", icon: <PrintIcon />, path: "/admin/imprimant" },
    { text: "Search", icon: <SearchIcon />, path: "/admin/searchCommand" },
  ],
  user: [
    { text: "Commande", icon: <PermMediaIcon />, path: "/admin/command" },
    { text: "Search", icon: <SearchIcon />, path: "/admin/searchCommand" },
  ],
};

export default function MenuDashboard() {
  const { user } = useSelector((state) => state.Auth);
  const role = user.Role;
  const open = true;
  return (
    <Box>
      <Drawer variant="permanent" open={open}>
        <Box sx={{ marginTop: "80px" }}>
          <ListDashboard open={open} role={role} />
        </Box>
      </Drawer>
    </Box>
  );
}

const ListDashboard = ({ open, role }) => {
  const handleDeconnection = () => {
    Cookies.remove("token");
    window.location.reload();
  };

  return (
    <List>
      {menuItems[role]?.map((item, index) => (
        <Link
          key={index}
          to={item.path}
          style={{ textDecoration: "none", color: "black" }}
        >
          <ListItem disablePadding sx={{ display: "block" }}>
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                {item.icon}
              </ListItemIcon>
              <ListItemText
                primary={item.text}
                sx={{ opacity: open ? 1 : 0 }}
              />
            </ListItemButton>
          </ListItem>
        </Link>
      ))}
    </List>
  );
};
