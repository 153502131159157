import React, { useState, useRef } from "react";
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  Slider,
  IconButton,
} from "@mui/material";
import RotateLeftIcon from "@mui/icons-material/RotateLeft";
import RotateRightIcon from "@mui/icons-material/RotateRight";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import CropIcon from "@mui/icons-material/Crop";

export default function EditImagePopup({
  open,
  onClose,
  src,
  demonsion,
  onSubmit,
}) {
  const [brightness, setBrightness] = useState(1);
  const [rotation, setRotation] = useState(0);
  const [displayCrop, setDisplayCrop] = useState(false);
  const [crop, setCrop] = useState({
    unit: "%",
    height: 100,
    aspect: demonsion?.typeCroperW / demonsion?.typeCroperH,
  });

  function handleSave() {}

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogContent>
        <div
          style={{
            textAlign: "center",
          }}
        >
          <div style={{ display: "flex", justifyContent: "center" }}>
            {displayCrop ? (
              <ReactCrop
                crop={crop}
                onChange={(newCrop) => setCrop(newCrop)}
                aspect={demonsion?.typeCroperW / demonsion?.typeCroperH}
              >
                <img
                  src={src}
                  alt="Edit me"
                  style={{
                    maxWidth: "auto",
                    height: "400px",
                    transform: `rotate(${rotation}deg)`,
                    filter: `brightness(${brightness})`,
                  }}
                />
              </ReactCrop>
            ) : (
              <img
                src={src}
                alt="Edit me"
                style={{
                  maxWidth: "auto",
                  height: "400px",
                  transform: `rotate(${rotation}deg)`,
                  filter: `brightness(${brightness})`,
                }}
              />
            )}
          </div>

          <div>
            <Slider
              value={brightness}
              onChange={(e, newValue) => {
                setBrightness(newValue);
                //  setDisplayCrop(false);
              }}
              step={0.1}
              min={0.5}
              max={2}
              sx={{ width: "50%" }}
            />
          </div>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <div>
              <IconButton
                size="large"
                onClick={() => {
                  setRotation(rotation - 90);
                  //setDisplayCrop(false);
                }}
              >
                <RotateLeftIcon />
              </IconButton>
              <IconButton
                size="large"
                onClick={() => {
                  setRotation(rotation + 90);
                  //setDisplayCrop(false);
                }}
              >
                <RotateRightIcon />
              </IconButton>
            </div>
            <div>
              <IconButton
                size="large"
                onClick={() => {
                  setDisplayCrop(true);
                  //setDisplayCrop(false);
                }}
              >
                <CropIcon />
              </IconButton>
            </div>
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button
          onClick={() => {
            onSubmit(crop, displayCrop, brightness, rotation);
          }}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}
