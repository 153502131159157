import {
  Box,
  Grid,
  TextField,
  Typography,
  InputAdornment,
} from "@mui/material";
import logo from "../../image/okprintLogo.jpg";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { Api_Token } from "../../env";
import CustomKeyboard from "../../composant/clavie/keyboard";
import KeyboardIcon from "@mui/icons-material/Keyboard";
import QRCode from "react-qr-code";
import { urlQrCode } from "../../env";
import { useSelector } from "react-redux";

export default function PageSearchCommand() {
  const [code, setCode] = useState(""); // Initialize as an empty string
  const [keyboardOpen, setKeyboardOpen] = useState(false);
  const [inputValue, setInputValue] = useState(""); // Added inputValue state for keyboard input
  const [currentLayout, setCurrentLayout] = useState("default");
  const { user } = useSelector((state) => state.Auth);
  const [qrValue, setQrValue] = useState(
    `${urlQrCode}?magasin=${user.idMagasin}`
  );
  const navigate = useNavigate();

  const handleKeyPress = (button) => {
    switch (button) {
      case "{shift}":
        setCurrentLayout((prevLayout) =>
          prevLayout === "default" ? "shift" : "default"
        );
        break;
      case "{numbers}":
        setCurrentLayout("numbers");
        break;
      case "{default}":
        setCurrentLayout("default");
        break;
      case "{delete}":
        if (inputValue) {
          const updatedValue = inputValue.slice(0, -1); // Remove the last character
          setInputValue(updatedValue);
          setCode(updatedValue);
        }
        break;
      default:
        if (inputValue.length < 6) {
          // Limit input to 6 characters
          setInputValue((prevValue) => prevValue + button);
          setCode((prevValue) => prevValue + button);
        }
        break;
    }
  };

  const handleOpenKeyboard = () => {
    setKeyboardOpen(true);
    setInputValue(code);
  };

  const handleCloseKeyboard = () => {
    setKeyboardOpen(false);
  };

  const onChange = (input) => {
    if (input.length <= 6) {
      setInputValue(input);
      setCode(input);
    }
  };

  useEffect(() => {
    const search = () => {
      Api_Token.get(`command/traitebycode/${code}`)
        .then((response) => {
          navigate(`/admin/imageTraite/${response.data.idCommand}`);
        })
        .catch((error) => {
          console.error("Error fetching command:", error);
          alert("Command not found");
        });
    };
    if (code.length === 6) {
      search();
    }
  }, [code, navigate]);

  const handleKeyDown = (event) => {
    if (event.key === "Enter" && code.length === 6) {
      //search();
    }
  };

  return (
    <Box
      sx={{
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "flex-start",
        paddingTop: "20px",
      }}
    >
      <Grid container textAlign="center" justifyContent="center" spacing={6}>
        <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }}>
          <Box
            component="img"
            src={logo}
            alt="OK Print Logo"
            sx={{ maxWidth: "300px", mb: 2, display: "block", margin: "auto" }}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography
            sx={{
              fontSize: {
                xs: "14px",
                md: "26px",
                lg: "28px",
                xl: "30px",
              },
            }}
          >
            Welcome to OK Print
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box sx={{ width: "70%", textAlign: "center" }}>
            <TextField
              fullWidth
              placeholder="Code de Command"
              label="Code de Command"
              name="code"
              value={code}
              onChange={(e) => {
                const value = e.target.value;
                if (value.length <= 6) setCode(value); // Limit input to 6 characters
              }}
              onKeyDown={handleKeyDown}
              autoComplete="off"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <KeyboardIcon
                      style={{ cursor: "pointer" }}
                      onClick={handleOpenKeyboard}
                    />
                  </InputAdornment>
                ),
              }}
            />
            {keyboardOpen && (
              <div
                style={{
                  bottom: 0,
                  width: "100%",
                  background: "#f4f4f4",
                  zIndex: 1000,
                  padding: "10px 0",
                  boxShadow: "0px -2px 5px rgba(0,0,0,0.1)",
                }}
              >
                <CustomKeyboard
                  inputValue={inputValue}
                  onChange={onChange}
                  onKeyPress={handleKeyPress}
                  layoutName={currentLayout}
                  closeKeyboard={handleCloseKeyboard}
                />
              </div>
            )}
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <QRCode value={qrValue} size={200} />
        </Grid>
      </Grid>
    </Box>
  );
}
