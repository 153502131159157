import { useEffect, useState, useCallback } from "react";
import { Api_Token } from "../../env";
import { Grid, Box, Typography } from "@mui/material";
import BurstModeIcon from "@mui/icons-material/BurstMode";
import PhotoLibraryIcon from "@mui/icons-material/PhotoLibrary";
import NumberGeneral from "../../composant/statistique/CardStatistique";
import Indexdasbord from "../../composant/navbar/indexDashbord";
import { TableCommands } from "../../composant/Tables/TableCommands";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import Datepicker from "react-tailwindcss-datepicker";
import { BackLoadingSimple } from "../../composant/loading/backLoading";
import { GetCommand } from "../../store/slice/CommandDahbordSlice";
import Paper from "@mui/material/Paper";

function PageDashboard() {
  return <Indexdasbord Children={<Dashboard />} />;
}
export default PageDashboard;

function Dashboard() {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const { commands, alertMessage, etat } = useSelector(
    (state) => state.commands
  );
  const dispatch = useDispatch();

  const [DateRange, setDateRange] = useState({
    startDate: null,
    endDate: null,
  });
  const formatDateForAPI = (date) => {
    return date ? new Date(date).toISOString().split("T")[0] : "";
  };

  const getCommadParData = useCallback(() => {
    const data = {
      page,
      rowsPerPage,
      DateRange: {
        startDate: formatDateForAPI(DateRange.startDate) || null,
        endDate: formatDateForAPI(DateRange.endDate) || null,
      },
    };

    dispatch(GetCommand(data));
  }, [dispatch, page, rowsPerPage, DateRange]);
  useEffect(() => {
    getCommadParData();
  }, [page, rowsPerPage, getCommadParData]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={12} sx={{ marginLeft: "2%", padding: "2%" }}>
          <NumberGeneral DateRange={DateRange} />
        </Grid>
        <Grid item xs={12} sx={{ padding: "2%", marginLeft: "2%" }}>
          <Paper sx={{ padding: "2%", minHeight: "500px" }}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Typography variant="h4">Commands</Typography>
              </Grid>
              <Grid item xs={6}>
                <Datepicker
                  inputClassName="w-full h-14 rounded-md focus:ring-0 font-normal bg-[#F8F8F8] border border-gray-300 placeholder:text-black-100 text-black dark:bg-black-900 dark:placeholder:text-black-100"
                  primaryColor={"blue"}
                  value={DateRange} // ✅ Ensure value is set
                  onChange={(newDateRange) => {
                    setDateRange(newDateRange);
                    setPage(0);
                  }}
                  showShortcuts={true}
                  showFooter={true}
                  popoverDirection="down"
                />
              </Grid>
              <Grid item xs={12}>
                <TableCommands
                  commands={commands}
                  refreshCommands={getCommadParData}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  handleChangePage={handleChangePage}
                  handleChangeRowsPerPage={handleChangeRowsPerPage}
                />
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
}
