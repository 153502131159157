// components/Impressions.jsx
import React from "react";
import {
  Grid,
  Card,
  CardMedia,
  Checkbox,
  IconButton,
  Box,
  Typography,
} from "@mui/material";
import ArrowCircleDownIcon from "@mui/icons-material/ArrowCircleDown";
import CropIcon from "@mui/icons-material/Crop";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import EditIcon from "@mui/icons-material/Edit";

export default function Impressions({
  allImages,
  selectedImages,
  handleSelectImage,
  downloadImage,
  openImageCropper,
  editCountImage,
}) {
  return (
    <Grid container spacing={2}>
      {allImages?.map((image, index) => (
        <Grid item xs={3} key={image.id}>
          <Card>
            <Box display="flex" justifyContent="space-between">
              <Checkbox
                checked={selectedImages.includes(image.id)}
                onChange={() => handleSelectImage(image.id)}
              />
              <Box display="flex" alignItems="center">
                <IconButton onClick={() => editCountImage(index, -1)}>
                  <RemoveIcon />
                </IconButton>
                <Typography variant="body1" mx={1}>
                  {image.Number}
                </Typography>
                <IconButton onClick={() => editCountImage(index, +1)}>
                  <AddIcon />
                </IconButton>
              </Box>
            </Box>
            <CardMedia
              onClick={() => handleSelectImage(image.id)}
              component="img"
              image={`${image.ImageTraites[0].url}?v=${new Date().getTime()}`}
              alt="Image Traitee"
            />
            <Box display="flex" justifyContent="space-between" p={1}>
              <IconButton onClick={() => openImageCropper(image)}>
                <EditIcon />
              </IconButton>
              <IconButton
                onClick={() =>
                  downloadImage(image.ImageTraites[0].url, image.id)
                }
              >
                <ArrowCircleDownIcon />
              </IconButton>
            </Box>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
}
