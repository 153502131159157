import { Card, Grid, Box, Typography } from "@mui/material";
import GroupsIcon from "@mui/icons-material/Groups";
import DirectionsBoatIcon from "@mui/icons-material/DirectionsBoat";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { Api_Token } from "../../env";
import BurstModeIcon from "@mui/icons-material/BurstMode";
import PhotoLibraryIcon from "@mui/icons-material/PhotoLibrary";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";

export const CardDataButton = ({ data }) => {
  return (
    <Card sx={{ padding: "8%", borderRadius: "5%" }}>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Grid container>
            <Grid item xs={12}>
              <Typography sx={{ color: "gray", fontSize: "13px" }}>
                {data.titre}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography>{data.Number}</Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          xs={6}
          sx={{
            display: "flex",
            justifyContent: "right",
            alignItems: "center",
          }}
        >
          {data.icon}
        </Grid>

        <Grid item xs={12} textAlign="center">
          <Link to={data.linkSousText} style={{ textDecoration: "none" }}>
            <Typography
              sx={{
                fontSize: "15px",
                color: "#064C91",
                textDecoration: "underline",
              }}
            >
              {data.linkSousText !== "" ? data.soustext : ""}
            </Typography>
          </Link>
        </Grid>
      </Grid>
    </Card>
  );
};

export const CardDataSimple = ({ data }) => {
  return (
    <Card sx={{ padding: "8%", borderRadius: "5%", height: "100%" }}>
      <Grid container spacing={2}>
        <Grid item xs={12} textAlign="center"></Grid>
        <Grid item xs={6}>
          <Grid container>
            <Grid item xs={12}>
              <Typography variant="h6">
                <b>
                  {data.Number} {data.titre}
                </b>
              </Typography>
            </Grid>
            {/** <Grid item xs={12}>
              <Typography>{data.Number}</Typography>
            </Grid>*/}
          </Grid>
        </Grid>
        <Grid
          item
          xs={6}
          sx={{
            display: "flex",
            justifyContent: "right",
            alignItems: "center",
          }}
        >
          {data.icon}
        </Grid>
        <Grid item xs={12}></Grid>

        <Grid item xs={12} textAlign="center">
          <Link to={data.linkSousText} style={{ textDecoration: "none" }}>
            <Typography
              sx={{
                fontSize: "15px",
                color: "#064C91",
                textDecoration: "underline",
              }}
            >
              {data.linkSousText !== "" ? data.soustext : ""}
            </Typography>
          </Link>
        </Grid>
      </Grid>
    </Card>
  );
};

function NumberGeneral({ DateRange }) {
  const formatDateForAPI = (date) => {
    return date ? new Date(date).toISOString().split("T")[0] : "";
  };
  //  const { StatistiqueGeneral } = useSelector((state) => state.Statistique);
  //  const { user } = useSelector((state) => state.AuthSlice);
  const [StatistiqueGeneral, setStatistiqueGeneral] = useState({});
  useEffect(() => {
    const startDate = formatDateForAPI(DateRange?.startDate);
    const endDate = formatDateForAPI(DateRange?.endDate);

    Api_Token.get("/command/GetStatistiqueDashboard", {
      params: { startDate, endDate },
    })
      .then((response) => {
        setStatistiqueGeneral(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [DateRange]);
  const Data = [
    {
      titre: "Command",
      icon: <BurstModeIcon fontSize="large" color="primary" />,
      Number: StatistiqueGeneral.commandCount || 0,
    },
    {
      titre: "Command Annulé",
      icon: <BurstModeIcon fontSize="large" color="primary" />,
      Number: StatistiqueGeneral.commandAnnule || 0,
      //textButton: "+ New customer​ ",
      //  LinkButton: `/${user.Role}/AddCustomer`,
    },
    {
      titre: "Command Payé",
      icon: <PhotoLibraryIcon fontSize="large" color="primary" />,
      Number: StatistiqueGeneral.commandPaye || 0,
      //  textButton: "+ New customer​ ",
      //  LinkButton: `/${user.Role}/AddCustomer`,
    },

    {
      titre: "Amount",
      icon: <AttachMoneyIcon fontSize="large" color="primary" />,
      Number: `${StatistiqueGeneral.Prix || 0} dh`,
      //  textButton: "+ New Booking ",
      //  LinkButton: `/${user.Role}/NewBooking`,
    },
  ];

  return (
    <Grid container spacing={2}>
      {Data.map((d) => (
        <Grid item md={3} xs={6}>
          <CardDataSimple data={d} />
        </Grid>
      ))}
    </Grid>
  );
}

export default NumberGeneral;
