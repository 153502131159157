import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  IconButton,
  TextField,
  Snackbar,
  Alert,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import { useDispatch, useSelector } from "react-redux";
import {
  AddImprimant,
  UpdateImprimant,
  DeleteImprimant,
  GetImprimantData,
  SwitchImprimant,
  inisialAlertMessage,
} from "../../store/slice/ImprimantDasbordSlice";
import { Delete } from "@mui/icons-material";
import DeleteConfirmationDialog from "../../composant/admin/confirmationDelete";
import BackLoadingSimple from "../../composant/loading/backLoading";
import Indexdasbord from "../../composant/navbar/indexDashbord";
import EditIcon from "@mui/icons-material/Edit";

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#65C466",
        opacity: 1,
        border: 0,
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

export default function PageImprimant() {
  const loading = useSelector((state) => state.imprimant.loading);
  return (
    <>
      <Indexdasbord Children={<ImprimantPage />} />
      <BackLoadingSimple open={loading} />
    </>
  );
}

function ImprimantPage() {
  const imprimant = useSelector((state) => state.imprimant.ImprimantData);
  const { alertMessage, etat } = useSelector((state) => state.imprimant);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [newName, setNewName] = useState("");
  const [newTime, setNewTime] = useState("");
  const [openPopup, setOpenPopup] = useState(false);
  const [editingId, setEditingId] = useState(null);
  const [openDelete, setOpenDelete] = useState(false);
  const [idDelete, setIdDelete] = useState();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(GetImprimantData());
  }, [dispatch]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  function Switch(id) {
    dispatch(SwitchImprimant(id)).then(() => {
      dispatch(GetImprimantData());
    });
  }

  function handleAddOrUpdate() {
    const data = { nom: newName, time: newTime };
    if (editingId) {
      dispatch(UpdateImprimant({ id: editingId, ...data })).then((result) => {
        if (UpdateImprimant.fulfilled.match(result)) {
          dispatch(GetImprimantData());
        }
      });
    } else {
      dispatch(AddImprimant(data)).then((result) => {
        if (AddImprimant.fulfilled.match(result)) {
          dispatch(GetImprimantData());
        }
      });
    }
    setOpenPopup(false);
    setNewName("");
    setNewTime("");
    setEditingId(null);
  }

  function suppImprimant() {
    setOpenDelete(false);
    dispatch(DeleteImprimant(idDelete)).then((result) => {
      if (DeleteImprimant.fulfilled.match(result)) {
        dispatch(GetImprimantData());
      }
    });
  }

  function handleClose() {
    dispatch(inisialAlertMessage());
  }

  function openEditPopup(id, name, time) {
    setEditingId(id);
    setNewName(name);
    setNewTime(time);
    setOpenPopup(true);
  }

  return (
    <Box>
      <Paper
        sx={{
          width: "90%",
          overflow: "hidden",
          marginLeft: "5%",
          padding: "3%",
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography variant="h4">Page : Imprimant</Typography>

          <Button
            variant="contained"
            sx={{ marginBottom: 2, marginLeft: 2 }}
            onClick={() => setOpenPopup(true)}
          >
            Ajouter Imprimant
          </Button>
        </Box>
        <TableContainer sx={{ maxHeight: 440 }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell sx={{ textAlign: "center" }}>Nom</TableCell>
                <TableCell sx={{ textAlign: "center" }}>time</TableCell>
                <TableCell sx={{ textAlign: "center" }}>Statut</TableCell>
                <TableCell sx={{ textAlign: "center" }}>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {imprimant?.data?.map((i) => (
                <TableRow key={i.id}>
                  <TableCell sx={{ textAlign: "center" }}>
                    {i.nomImprimant}
                  </TableCell>
                  <TableCell sx={{ textAlign: "center" }}>{i.time}</TableCell>
                  <TableCell sx={{ textAlign: "center" }}>
                    <FormControlLabel
                      checked={i.etat}
                      control={<IOSSwitch sx={{ m: 1 }} defaultChecked />}
                      label={i.etat ? "on" : "off"}
                      onClick={() => {
                        if (!i.etat) {
                          Switch(i.id);
                        }
                      }}
                    />
                  </TableCell>
                  <TableCell sx={{ textAlign: "center" }}>
                    <IconButton
                      onClick={() =>
                        openEditPopup(i.id, i.nomImprimant, i.time)
                      }
                      variant="outlined"
                    >
                      <EditIcon />
                    </IconButton>
                    <IconButton
                      onClick={() => {
                        setIdDelete(i.id);
                        setOpenDelete(true);
                      }}
                    >
                      <Delete color="error" />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 15]}
          component="div"
          count={imprimant?.totalCount || 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      <Snackbar
        open={alertMessage}
        autoHideDuration={3000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={handleClose}
          severity={etat.error ? "error" : "success"}
          variant="filled"
          sx={{ width: "100%" }}
        >
          {etat.message}
        </Alert>
      </Snackbar>
      <DeleteConfirmationDialog
        open={openDelete}
        onClose={() => setOpenDelete(false)}
        onConfirm={suppImprimant}
      />
      <Dialog open={openPopup} onClose={() => setOpenPopup(false)}>
        <DialogTitle>
          {editingId ? "Modifier Imprimant" : "Ajouter Imprimant"}
        </DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            label="Nom Imprimant"
            variant="outlined"
            value={newName}
            onChange={(e) => setNewName(e.target.value)}
            sx={{ marginBottom: 2 }}
          />
          <TextField
            fullWidth
            label="time"
            variant="outlined"
            value={newTime}
            onChange={(e) => setNewTime(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenPopup(false)} color="secondary">
            Annuler
          </Button>
          <Button color="primary" onClick={handleAddOrUpdate}>
            {editingId ? "Modifier" : "Ajouter"}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
