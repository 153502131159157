import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { Api_Token } from "../../env";

//command/getcommad?page=${page}&limit=${rowsPerPage
export const GetCommand = createAsyncThunk("command", async (data) => {
  const queryParams = new URLSearchParams({
    page: data.page + 1,
    limit: data.rowsPerPage,
  });
  if (data.codeFilter) queryParams.append("code", data.codeFilter);
  if (data.nomFilter) queryParams.append("nom", data.nomFilter);
  if (data.telephoneFilter)
    queryParams.append("telephone", data.telephoneFilter);
  if (data.DateRange.startDate)
    queryParams.append("startDate", data.DateRange.startDate);
  if (data.DateRange.endDate)
    queryParams.append("endDate", data.DateRange.endDate);

  const response = await Api_Token.get(`command/get?${queryParams.toString()}`);
  return response.data;
});

export const TraiteCommand = createAsyncThunk(
  " command ",
  async (data, { rejectWithValue }) => {
    try {
      const response = await Api_Token.post(`command/traite`, data);
      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

export const PayeCommand = createAsyncThunk(
  "paye command ",
  async (data, { rejectWithValue }) => {
    try {
      const response = await Api_Token.post(`command/PayeCommand`, data);
      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);
export const AnnuleCommand = createAsyncThunk(
  "annule command ",
  async (data, { rejectWithValue }) => {
    try {
      const response = await Api_Token.post(`command/AnnuleCommand`, data);
      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

export const DeleteCommand = createAsyncThunk(
  "Delete command ",
  async (data, { rejectWithValue }) => {
    try {
      const response = await Api_Token.post(`command/DeleteCommand`, data);
      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

export const GetImageParCommand = createAsyncThunk(
  "images traite par id ",
  async (id, { rejectWithValue }) => {
    try {
      const response = await Api_Token.get(`command/imageTraite/${id}`);
      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

export const EditeImage = createAsyncThunk(
  "croper image ",
  async (data, { rejectWithValue }) => {
    try {
      const response = await Api_Token.post(`command/editImage`, data);
      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

export const GetStatus = createAsyncThunk("get status", async () => {
  const response = await Api_Token.get("status/getAll");
  return response.data;
});

export const EditStatus = createAsyncThunk("edit Status", async (data) => {
  const response = await Api_Token.post(`command/editStatus`, data);
  return response.data;
});

const initialState = {
  loading: false,
  commands: {},
  PopupData: {},
  alertMessage: false,
  etat: {
    error: false,
    message: "",
  },
  status: [],
};

export const CommandDashbordSlice = createSlice({
  name: "command",
  initialState,
  reducers: {
    inisialAlertMessageCommand: (state, action) => {
      state.alertMessage = false;
      state.etat = {
        error: false,
        message: "",
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(GetCommand.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(GetCommand.fulfilled, (state, action) => {
      state.loading = false;
      state.commands = action.payload;
    });
    builder.addCase(GetCommand.rejected, (state, action) => {
      state.loading = false;
    });

    builder.addCase(TraiteCommand.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(TraiteCommand.fulfilled, (state, action) => {
      state.alertMessage = true;
      state.etat.message = action.payload;
    });
    builder.addCase(TraiteCommand.rejected, (state, action) => {
      state.loading = false;
      state.alertMessage = true;
      state.etat.error = true;
      state.etat.message = action.payload;
    });
    builder.addCase(GetImageParCommand.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(GetImageParCommand.fulfilled, (state, action) => {
      state.loading = false;
      state.PopupData = action.payload;
    });
    builder.addCase(GetImageParCommand.rejected, (state, action) => {
      state.loading = false;
      state.alertMessage = true;
      state.etat.error = true;
      state.etat.message = action.error.message;
    });
    builder.addCase(EditeImage.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(EditeImage.rejected, (state, action) => {
      state.loading = false;
      state.state.alertMessage = true;
      state.etat.error = true;
      state.etat.message = action.payload;
    });
    builder.addCase(GetStatus.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(GetStatus.fulfilled, (state, action) => {
      state.loading = false;
      state.status = action.payload;
    });
    builder.addCase(GetStatus.rejected, (state, action) => {
      state.loading = false;
    });
    builder.addCase(EditStatus.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(EditStatus.fulfilled, (state, action) => {
      state.loading = false;
      state.alertMessage = true;
      state.etat.message = action.payload.message;
    });
    builder.addCase(EditStatus.rejected, (state, action) => {
      state.loading = false;
      state.state.alertMessage = true;
      state.etat.error = true;
      state.etat.message = action.payload;
    });
  },
});

export default CommandDashbordSlice.reducer;
export const { inisialAlertMessageCommand } = CommandDashbordSlice.actions;
