import { Box, Grid, Typography } from "@mui/material";
import { Link } from "react-router-dom/dist";
import event from "../../image/event.jpg";
import { useSelector } from "react-redux";
import DrawerAppBar from "../../composant/navbar/Navbar";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { StorageDataClient } from "../../store/slice/InfoClientSlice";
const stylebox = {
  cursor: "pointer",
  color: "black",
  outline: "none",
  fontWeight: "bold",
};

const TextStyle = {
  fontWeight: "bold",
  fontSize: "1rem",
};

export default function Home() {
  const { Produits } = useSelector((state) => state.dataApp);
  const dispatch = useDispatch();

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const magasin = urlParams.get("magasin");
    if (magasin) {
      const existingData = JSON.parse(localStorage.getItem("infoclient")) || {};
      const magasinId = parseInt(magasin);
      const updatedData = { ...existingData, magasin: magasinId };
      localStorage.setItem("infoclient", JSON.stringify(updatedData));
      dispatch(StorageDataClient(updatedData));
    }
  }, [dispatch]);

  return (
    <>
      <DrawerAppBar />
      <div style={{ background: "#f7f7f7", minHeight: "100vh" }}>
        <Box>
          <Grid
            container
            textAlign="center"
            marginTop="5%"
            spacing={4}
            marginRight="auto"
            rowSpacing={4}
            padding="2%"
          >
            <Grid item xs={12} textAlign="centre">
              <Box sx={stylebox}>
                {" "}
                <Typography
                  sx={{
                    fontSize: {
                      xs: "24px",
                      md: "36px",
                      lg: "48px",
                      xl: "60px",
                    },
                    fontWeight: "bold",
                  }}
                >
                  Hello
                </Typography>
                <Typography
                  sx={{
                    fontSize: {
                      // for extra-small screens
                      xs: "14px", // for small screens
                      md: "26px", // for medium screens
                      lg: "28px", // for large screens
                      xl: "30px", // for extra-large screens
                    },
                  }}
                >
                  welcome to ok Print
                </Typography>
              </Box>
            </Grid>
            {Produits?.map((p) => (
              <Grid item xs={6}>
                <Link
                  to={`/produit/${p.NomProduit}`}
                  style={{ textDecoration: "none" }}
                >
                  <Box sx={stylebox}>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <Box
                        component="img"
                        src={p.url_Image}
                        alt="polaroid"
                        sx={{
                          borderRadius: "30px",
                          width: { xs: "100%", sm: "50%" }, // This will be 50% on extra small screens and 100% on small screens and up
                        }}
                      />
                    </div>

                    <Typography variant="h6" sx={TextStyle}>
                      Tirage {p.NomProduit}
                    </Typography>
                  </Box>
                </Link>
              </Grid>
            ))}
            {/* 
        <Grid item xs={6}>
          <Link to="/polaroid" style={{ textDecoration: "none" }}>
            <Box sx={stylebox}>
              <Box
                component="img"
                src={polaroid}
                alt="polaroid"
                sx={{
                  borderRadius: "30px",
                  width: { xs: "100%", sm: "50%" }, // This will be 50% on extra small screens and 100% on small screens and up
                }}
              />
              <Typography variant="h6" sx={TextStyle}>
                Tirage polaroid
              </Typography>{" "}
            </Box>
          </Link>
        </Grid>
        <Grid item xs={6}>
          <Link to="/instax" style={{ textDecoration: "none" }}>
            <Box sx={stylebox}>
              <Box
                component="img"
                src={instax}
                alt="instax"
                sx={{
                  borderRadius: "30px",
                  width: { xs: "100%", sm: "50%" }, // This will be 50% on extra small screens and 100% on small screens and up
                }}
              />
              <Typography variant="h6" sx={TextStyle}>
                Tirage instax
              </Typography>
            </Box>
          </Link>
        </Grid>*/}
            <Grid item xs={12} sx={{ textAlign: "center" }}>
              <Link to="/produit/instax" style={{ textDecoration: "none" }}>
                <Box>
                  <Box
                    component="img"
                    src={event}
                    alt="instax"
                    sx={{
                      borderRadius: "30px",
                      width: { xs: "100%", sm: "80%" }, // 100% on extra small, 80% on small and up
                      display: "block",
                      margin: "0 auto",
                    }}
                  />
                  <Typography variant="h4" color="black" sx={TextStyle}>
                    Evenements
                  </Typography>
                </Box>
              </Link>
            </Grid>
          </Grid>
        </Box>
      </div>
    </>
  );
}
