import { Route, Routes, Navigate } from "react-router";
import { BrowserRouter } from "react-router-dom";
import { CssBaseline } from "@mui/material";
import { useSelector } from "react-redux";

import Home from "./page/Client/home";
import InfoClient from "./page/Client/Checkout";
import Panier from "./page/Client/Panier";
import ImageUpload from "./page/Client/UploadImagePage";
import Login from "./page/Admin/auth/login";
import PageCommand from "./page/Admin/PageCommad";
import PageImprimant from "./page/Admin/PageImprimant";
import PageParams from "./page/Admin/PageParams";
import PageSearchCommand from "./page/Admin/PageSearchCommand";
import PageCommandTraiteParCode from "./page/Admin/PageCommandTraiteParcode";
import PageImageToday from "./page/Admin/PageImageToday";
import PageCommandClient from "./page/Admin/CommandClientPage";
import PageDashboard from "./page/Admin/PageDashboard";
import TestRollete from "./page/Client/testRoulete";

const privateRoutes = [
  { path: "/admin", element: <PageDashboard />, roles: ["gerant"] },
  {
    path: "/admin/command",
    element: <PageCommand />,
    roles: ["user", "gerant"],
  },
  {
    path: "/admin/imprimant",
    element: <PageImprimant />,
    roles: ["gerant"],
  },
  {
    path: "/admin/parametre",
    element: <PageParams />,
    roles: ["gerant"],
  },
  {
    path: "/admin/searchCommand",
    element: <PageSearchCommand />,
    roles: ["user", "gerant"],
  },
  {
    path: "/admin/imageTraite/:id",
    element: <PageCommandTraiteParCode />,
    roles: ["user", "gerant"],
  },
  {
    path: "/admin/ImageTodayPage/",
    element: <PageImageToday />,
    roles: ["admin", "staff"],
  },
  {
    path: "/admin/CommandClientPage/:id",
    element: <PageCommandClient />,
    roles: ["user", "gerant"],
  },
];

function IndexRoute() {
  const { IsAuth, user } = useSelector((state) => state.Auth);
  const role = user.Role;
  return (
    <BrowserRouter>
      <CssBaseline />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/produit/:type" element={<ImageUpload />} />
        <Route path="/panier" element={<Panier />} />
        <Route path="/commande/:type" element={<InfoClient />} />
        <Route path="/TestRollete" element={<TestRollete />} />
        <Route
          path="/login"
          element={IsAuth ? <Navigate to="/admin/command" /> : <Login />}
        />
        {privateRoutes.map((route) => (
          <Route
            key={route.path}
            path={route.path}
            element={
              IsAuth && route.roles.includes(role) ? (
                route.element
              ) : (
                <Navigate state={{ from: route.path }} to="/login" />
              )
            }
          />
        ))}
      </Routes>
    </BrowserRouter>
  );
}

export default IndexRoute;
