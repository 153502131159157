import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Box,
  Button,
  Typography,
} from "@mui/material";
import image from "../../image/hashtag-polaroid.jpg";

export function ConfirmClientTextPopup({ open, onClose, confirm }) {
  return (
    <Dialog open={open} fullWidth maxWidth="xs">
      <DialogTitle
        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        <img src={image} height="200" />
      </DialogTitle>

      <DialogContent>
        <Typography>Voulez-vous ajouter des Hashtag?</Typography>
      </DialogContent>
      <DialogActions>
        <Box sx={{ display: "flex", flexDirection: "row" }}>
          <Button variant="contained" color="error" onClick={confirm}>
            No
          </Button>
          <Button
            variant="contained"
            onClick={onClose}
            sx={{ marginLeft: "2%" }}
          >
            oui
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
}
