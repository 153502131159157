import React, { createContext, useState, useCallback, useContext } from "react";
import CustomAlert from "../composant/alerts/AlertCustomer";

export const AlertContext = createContext({
  showAlert: () => {},
});

export const useAlertContext = () => {
  const context = useContext(AlertContext);
  return context;
};

export const AlertProvider = ({ children }) => {
  const [alert, setAlert] = useState({
    open: false,
    message: "",
    etat: "info",
  });

  const showAlert = useCallback((message, etat = "info") => {
    setAlert({ open: true, message, etat });
  }, []);

  const handleClose = useCallback(() => {
    setAlert((prev) => ({ ...prev, open: false }));
  }, []);

  return (
    <AlertContext.Provider value={{ alert, showAlert }}>
      {children}
      <CustomAlert
        open={alert.open}
        onClose={handleClose}
        etat={alert.etat}
        message={alert.message}
      />
    </AlertContext.Provider>
  );
};
