import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { api_base } from "../../env";
import Cookies from "js-cookie";
import axios from "axios";
import { Api_Token } from "../../env";
import { jwtDecode } from "jwt-decode";

export const send_login = createAsyncThunk("login", async (data) => {
  const response = await axios.post(`${api_base}users/login`, data);
  return response.data;
});

export const get_user = createAsyncThunk("user", async () => {
  const response = await Api_Token.get("/users/user");
  return response.data;
});

const initialState = {
  loading: false,
  IsAuth: false,
  TypeUser: null,
  user: {},
  userInfo: {},
};

export const AuthSlice = createSlice({
  name: "Auth",
  initialState,
  reducers: {
    checkIsAuth: (state, action) => {
      state.IsAuth = Cookies.get("token") ? true : false;
    },
    Logout: (state, action) => {
      state.IsAuth = false;
      state.user = {};
      state.userInfo = {};
      Cookies.remove("token");
    },
    CheckEmailRegulaire: (state, action) => {
      const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
      return emailPattern.test(action.email);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(send_login.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(send_login.fulfilled, (state, action) => {
      state.loading = false;
      Cookies.set("token", action.payload.token, { expires: 1 });
      state.IsAuth = true;
      state.user = action.payload.user;
      state.userInfo = action.payload.user;
    });
    builder.addCase(send_login.rejected, (state, action) => {
      state.loading = false;
    });

    builder.addCase(get_user.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(get_user.fulfilled, (state, action) => {
      state.loading = false;
      state.user = action.payload;
      state.userInfo = action.payload;
    });
    builder.addCase(get_user.rejected, (state, action) => {
      state.loading = false;
    });
  },
});

export default AuthSlice.reducer;
export const { checkIsAuth, Logout } = AuthSlice.actions;
