// hooks/useImpressions.js
import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import axios from "axios";
import {
  EditeImage,
  GetImageParCommand,
} from "../store/slice/CommandDahbordSlice";
import { Api_Token } from "../env";

export function useImpressions(initialImages, commandId, imprimant) {
  const dispatch = useDispatch();
  const [allImages, setAllImages] = useState(initialImages || []);
  const [selectedImages, setSelectedImages] = useState([]);
  const [openCropper, setOpenCropper] = useState(false);
  const [demonsion, setDemonsion] = useState({});
  const [src, setSrc] = useState(null);
  const [idImage, setIdImage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [openPopup, setOpenPopup] = useState(false);

  useEffect(() => {
    setAllImages(initialImages);
  }, [initialImages]);

  const editCountImage = (index, number) => {
    setAllImages((prev) => {
      const updatedImages = [...prev];
      updatedImages[index] = {
        ...updatedImages[index],
        Number: updatedImages[index].Number + number,
      };
      return updatedImages;
    });
  };

  const handleSelectImage = (id) => {
    setSelectedImages((prev) =>
      prev.includes(id) ? prev.filter((imgId) => imgId !== id) : [...prev, id]
    );
  };

  const handleSelectAll = () => {
    if (selectedImages.length === allImages.length) {
      setSelectedImages([]);
    } else {
      setSelectedImages(allImages.map((img) => img.id));
    }
  };

  const openImageCropper = (image) => {
    setIdImage(image.id);
    setSrc(image.url);
    setDemonsion(
      imprimant?.demontions?.find((d) => image.idProduit === d.idProduit)
    );
    setOpenCropper(true);
  };

  const sendToPrint = async () => {
    setLoading(true);
    const selectedImageUrls = allImages
      .filter((image) => selectedImages.includes(image.id))
      .map((image) => ({
        url: image.ImageTraites[0].url,
        type: image.Produit?.NomProduit,
        Number: image.Number,
      }));
    const data = {
      imprimant: imprimant?.nomImprimant,
      image_urls: selectedImageUrls,
      time: imprimant.time,
    };

    try {
      const response = await axios.post(
        "http://127.0.0.1:5000/print_images",
        data
      );
      if (response.status === 200 || response.status === 207) {
        const results = response.data.results;
        const allPrintedSuccessfully = results.every(
          (result) => result.message === "Image printed successfully"
        );

        if (allPrintedSuccessfully) {
          setOpenPopup(true);
          setLoading(false);
        } else {
          setLoading(false);
          console.error("Some images failed to print");
        }
        Api_Token.post(`command/editPriceCommand`, {
          idCommand: commandId,
          numberImageImprime: response.data.total_printed,
        });
      } else {
        console.error("Failed to send images. Status code:", response.status);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.error("Error sending images:", error);
    }
  };

  const editImage = (crop, displayCrop, brightness, rotation) => {
    setOpenCropper(false);
    const data = {
      ...crop,
      displayCrop,
      brightness,
      rotation,
      id: idImage,
    };
    dispatch(EditeImage(data)).then((result) => {
      if (EditeImage.fulfilled.match(result)) {
        dispatch(GetImageParCommand(commandId));
      }
    });
  };

  return {
    allImages,
    selectedImages,
    openCropper,
    demonsion,
    src,
    loading,
    openPopup,
    handleSelectImage,
    handleSelectAll,
    editCountImage,
    sendToPrint,
    editImage,
    openImageCropper,
    setOpenCropper,
    setOpenPopup,
  };
}
